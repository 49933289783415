.pw-container {
  position: relative;
  width: 70%;
  margin: 10px auto;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  right: 41.8%;
  margin-top: -15px;
}

.password-container {
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.input {
  width: 130%;
  font-size: 24px;
  border-radius: 5px;
  border: 2px solid var(--gray-line);
  padding: 5px;
}

.input:focus {
  border-color: var(--gray);
  outline: none;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  position: relative;
  right: 42%;
  top: 20%;
  margin-top: 4%;
}

.back-button {
  display: inline-block;
  position: relative;
  color: #ff7708;
  padding: 0;
  border: none;
  background: none;
  font-size: 15px;
  cursor: pointer;
  width: fit-content; /* Adjust the width of the back button based on its content */
}

.cancel-button {
  display: inline-block;
  position: relative;
  color: #ff7708;
  padding: 0;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.eye-icon {
  /* eye icon to toggle password visibiility, used for first and third input boxes */
  position: relative;
  transform: translateY(-300%);
  right: 33%;
  cursor: pointer;
  color: #6d6b6b;
  width: 5%;
  left: 58%;
  bottom: 1px;
}

.eye-icon3 {
  /* eye icon for the third input box, needs its own styling since the password requirements message appear under this box and mess up the spacing*/
  position: absolute;
  transform: translateY(-300%);
  right: 33%;
  cursor: pointer;
  color: #6d6b6b;
  width: 5%;
  left: 105.5%;
  top: 255px;
}

#password-error {
  /* error message for password requirements */
  color: red;
}

.success-message {
  /* success message */
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    right: 24.8%;
    margin-top: -15px;
  }

  .password-container {
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    position: relative;
    right: 22%;
    margin-top: 10%;
  }

  .input {
    width: 100%;
    font-size: 24px;
    border-radius: 5px;
    border: 2px solid var(--gray-line);
    padding: 5px;
  }

  .eye-icon {
    /* eye icon to toggle password visibiility, used for first and third input boxes */
    position: relative;
    transform: translateY(-300%);
    right: 33%;
    cursor: pointer;
    color: #6d6b6b;
    width: 5%;
    left: 45%;
    bottom: 1px;
  }

  .eye-icon3 {
    /* eye icon for the third input box, needs its own styling since the password requirements message appear under this box and mess up the spacing*/
    position: absolute;
    transform: translateY(-300%);
    right: 33%;
    cursor: pointer;
    color: #6d6b6b;
    width: 5%;
    left: 93.5%;
    top: 255px;
  }
}

@media screen and (min-width: 575px) and (max-width: 1050px) {
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    right: 34.8%;
    margin-top: -15px;
  }

  .password-container {
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    position: relative;
    right: 34%;
    margin-top: 6%;
  }
}
