.user-bar-container {
  width: 80%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profile-switcher-button {
  padding: 10px 12px;
  font-size: 0.8rem !important;
  width: fit-content;
  align-self: flex-end;
}

.profile-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.profile-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
}

.user-image {
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  min-width: 80px;
  min-height: 80px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.user-image:hover {
  filter: brightness(80%);
}
.user-image-nohover:hover {
  filter: brightness(100%);
}
.user-image:hover .user-image-button {
  opacity: 8;
}

.user-image-button {
  position: absolute;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0;
  margin: auto;
  color: white;
  background: #7e7e7ef0;
  outline: none;
  border: none;
  border-radius: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 7%;
  padding-bottom: 7%;
  width: 80%;
  font-weight: 500;
  filter: brightness(100%);
}

.user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media screen and (max-width: 875px) {
  .bar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    width: 100%;
  }

  .profile-right {
    align-self: flex-end;
  }

  .user-bar-container {
    width: 90%;
    margin-top: 30px;
  }
}

.show-reviews-btn {
  padding: 12px 8px;
  align-self: flex-end;
}

.listings-profile {
  padding-top: 0 !important;
}

.copied-text {
  position: fixed;
  transition: all 0.3s ease-in-out;
  color: var(--gray);
  font-weight: 600;
  font-size: smaller;
  margin-left: -35px;
  margin-top: -70px;
}
