/* General Layout */
.admin-dashboard {
  display: flex;
  height: 100vh;
  /* Full screen height */
  flex-direction: row;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  position: fixed;
  top: 80px;
  /* Adjust to match the height of the header */
  left: 0;
  bottom: 0;
  z-index: 2;
  /* Ensure it appears above the main content */
  overflow-y: auto;
  /* Allow scrolling if content overflows */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
}

.sidebar ul li:hover,
.sidebar ul li.active {
  background-color: #ff7708;
  color: white;
}

/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  /* Set the height of the header */
  background-color: #ffffff;
  /* Ensure the header background is distinct */
  border-bottom: 1px solid #ddd;
  z-index: 3;
  /* Ensure the header is above everything else */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Optional shadow for better visibility */
}

/* Main Content */
.main-content {
  margin-left: 200px;
  /* Adjust based on sidebar width */
  padding: 100px 20px 20px;
  /* Add padding to accommodate the header */
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 80px);
  /* Adjust height to account for the header */
}

/* User Info Box */
.user-info-box {
  margin-top: 20px;
}

/* User Table Styling */
.user-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: left;
  border-spacing: 0;
}

.user-table th,
.user-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.user-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
  /* Light gray background */
}

.user-table tbody tr:nth-child(even) {
  background-color: #ffffff;
  /* White background */
}

.user-table tbody tr:hover {
  background-color: #ffe6cc;
  /* Slightly orange background on hover */
}

/* Spinner */
.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff7708;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Buttons */
.button-primary {
  padding: 8px 15px;
  background-color: #ffcc99;
  /* Light orange */
  color: black;
  /* Black text for contrast */
  border: 1px solid #ff7708;
  /* Orange border */
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  /* Adds spacing between email and button */
  transition: background-color 0.3s ease;
}

.button-primary:hover {
  background-color: #ffa366;
  /* Slightly darker orange on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    padding: 10px;
  }

  .main-content {
    margin-left: 0;
    padding: 90px 10px 20px;
    /* Ensure proper spacing */
  }
}
