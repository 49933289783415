.top-right {
  display: flex;
  flex-direction: row;
}

.maker-button {
  position: absolute;
  right: 150px;
  top: 21px;
  font-size: 1rem;
}

.exit-button {
  position: absolute;
  right: 30px;
}

.dropdown-container {
  right: 30px;
  top: 13px;
  position: absolute;
  border-radius: var(--border-radius-large);
}

.dc-dark {
  background-color: var(--db-background-secondary);
}

.dco-dark {
  border: solid 2px var(--db-background-secondary) !important;
  background-color: var(--db-background-secondary) !important;
}
.hamburger-button {
  border: solid 2px var(--gray-line);
  width: 110px;
  height: 60px;
  border-radius: var(--border-radius-large);
  background: rgba(255, 255, 255, 0);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  padding-top: 5px;
  padding-left: 14px;
  padding-right: 14px;
  float: right;
}

.hamburger-button:hover {
  box-shadow: var(--box-shadow);
}

.hamburger-open {
  box-shadow: none;
  border: 2px solid rgba(206, 25, 25, 0) !important;
  z-index: 5000;
}
.hamburger-open:hover {
  box-shadow: none;
}

.hamburger-button svg {
  pointer-events: none;
}

.account-picture {
  width: 40px;
  height: 40px;
  padding-bottom: 1px;
  border-radius: 50%;
}

.dropdown-items {
  z-index: 100;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
  background-color: var(--background);
  height: 100%;
  border-radius: var(--border-radius-large);
  border: solid 2px var(--gray-line);
  display: none;
  box-shadow: var(--box-shadow);
}

.dropdown-open {
  display: block;
  padding-right: 5%;
}

.dropdown-item {
  width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  background: none;
  border: none;
}

.dropdown-item:hover .dropdown-icon,
.dropdown-item:hover .dropdown-text {
  filter: invert(52%) sepia(71%) saturate(2389%) hue-rotate(353deg)
    brightness(101%) contrast(106%);
}
.dropdown-icon {
  width: 60px;
  pointer-events: none;
}
.dropdown-icon svg {
  width: 35px;
  height: 35px;
  pointer-events: none;
}

.dropdown-text {
  align-self: flex-start;
  color: var(--gray);
  font-size: 17px;
}

.dropdown-line {
  color: var(--gray-line);
  width: 150px;
}

.dropdown-item-disabled {
  cursor: default !important;
  color: var(--disabled) !important;
  filter: invert(75%) sepia(22%) saturate(28%) hue-rotate(315deg)
    brightness(92%) contrast(109%) !important;
}

.dropdown-item-disabled:hover .dropdown-icon,
.dropdown-item-disabled:hover .dropdown-text {
  filter: none !important;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .hamburger-button {
    width: 81px;
    height: 10px;
    padding-bottom: 40px;
    background: white;
    cursor: pointer;
    float: right;
  }
  .maker-button {
    opacity: 0;
    z-index: -1000;
  }

  .account-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
  }

  .three-bars {
    width: 30px;
    height: 30px;
    position: relative;
    top: 2px;
  }
}

@media screen and (min-width: 575px) and (max-width: 1050px) {
  .hamburger-button {
    width: 92px;
    height: 52px;
    padding-bottom: 35px;
    background: white;
    cursor: pointer;
    float: right;
  }

  .maker-button {
    right: 130px;
  }

  .account-picture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
  }

  .three-bars {
    width: 35px;
    height: 35px;
    position: relative;
    top: 2px;
  }
}

@media screen and (max-width: 420px) {
  .exit-button {
    right: 20px;
  }
}
