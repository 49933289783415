@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");

html {
  font-family: "Open Sans", sans-serif;
  overflow-y: auto;
  /* keeps page stable on scrollbar show and hide */
  scrollbar-gutter: stable;
  scrollbar-color: var(--orange-hover) white;
}

.html::-webkit-scrollbar-thumb {
  color: var(--orange);
}

.html::-webkit-scrollbar-track {
  background-color: var(--background);
}

::-webkit-scrollbar {
  background: var(--background);
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: var(--orange-hover);
  border-radius: 15px;
}

.sans * {
  font-family: "Open Sans", sans-serif;
}
.App {
  min-height: 90vh;
  background-color: var(--background);
}
:root {
  --dark-blue: #041538;
  --terciary-gray: rgb(240, 240, 240);
  --mild-gray: rgb(228, 228, 228);
  --actual-terciary: var(--terciary-gray);
  --background-secondary: var(--db-background-secondary);
  --db-background-secondary: rgb(255, 255, 255);

  --Maker--orange: #ff7708;
  --Maker--orange-hover: #ff6708;
  --Maker--gray-line: #4040408d;
  --Maker--gray: #000000;
  --Maker--light-gray: #6a6a6a;
  --Maker--disabled: #656565;
  --Maker--box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  --Maker--input-radius: 3px;
  --Maker--input-background: #585858;
  --Maker--background: #f0f0f0;
  --Maker--border-radius-large: 2px;
  --Maker--border-radius-medium: 2px;
  --Maker--border-radius-small: 2px;
  --Maker--actual-terciary: rgb(210, 210, 210);
  --Maker--orange-or-black: #ff7708;
  --Maker--white-or-black: #ffffff;
  --Maker--o-w-hover: rgba(255, 255, 255, 0.288);

  --Taker--orange: #ff7708;
  --Taker--orange-hover: #ff6708;
  --Taker--gray-line: #70707020;
  --Taker--gray: #484848;
  --Taker--light-gray: #6a6a6a;
  --Taker--disabled: #bdbdbd;
  --Taker--box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  --Taker--input-radius: 12px;
  --Taker--input-background: #f6f6f6;
  --Taker--background: #ffffff;
  --Taker--border-radius-large: 15px;
  --Taker--border-radius-medium: 10px;
  --Taker--border-radius-small: 5px;
  --Taker--actual-terciary: rgb(240, 240, 240);
  --Taker--orange-or-black: #f6f6f6;
  --Taker--white-or-black: #484848;
  --Taker--o-w-hover: rgb(225, 225, 225, 0.839);

  --orange: var(--Taker--orange);
  --orange-hover: var(--Taker--orange-hover);
  --gray-line: var(--Taker--gray-line);
  --gray: var(--Taker--gray);
  --light-gray: var(--Taker--light-gray);
  --disabled: var(--Taker--disabled);
  --box-shadow: var(--Taker--box-shadow);
  --input-radius: var(--Taker--input-radius);
  --input-background: var(--Taker--input-background);
  --background: var(--Taker--background);
  --border-radius-large: var(--Taker--border-radius-large);
  --border-radius-medium: var(--Taker--border-radius-medium);
  --border-radius-small: var(--Taker--border-radius-small);
  --actual-terciary: var(--Taker--actual-terciary);
  --orange-or-black: var(--Taker--orange-or-black);
  --white-or-black: var(--Taker--white-or-black);
  --o-w-hover: var(--Taker--hover);
}

.page-container {
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin-top: 80px;
}
.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vw;
  margin-inline: auto;
  text-align: center;
  text-wrap: balance;
  gap: 1rem;
  margin-top: 2rem;
}

.listing-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
h1,
h3,
p {
  color: var(--gray);
}

p {
  font-size: 18px;
}

.root {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.bold {
  font-weight: 500;
}

.light {
  font-weight: 300;
}
.italic {
  font-style: italic;
}
.left {
  text-align: left;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.subheader {
  font-size: 32px;
  font-weight: bold;
  width: fit-content;
}

.profile-header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
}

.profile-subheader {
  width: fit-content;
  font-weight: 600;
  color: var(--gray);
  margin-left: 2px;
}
.ps1 {
  font-size: 1.1rem;
}
.ps2 {
  font-size: 0.9rem;
}
.secondary-subheader {
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  color: var(--gray);
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.button-primary {
  border-radius: var(--border-radius-medium);
  border: 2px solid #ff7708;
  background-color: #ff7708;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.button-primary:hover {
  background-color: var(--orange-hover);
}

.button-secondary {
  border-style: hidden;
  border: 2px solid var(--gray-line);
  border-radius: var(--border-radius-medium);
  background-color: var(--white);
  color: var(--gray);
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s all;
}

.button-secondary:hover {
  border: 2px solid var(--orange);
}

.universal-borderless-button {
  display: inline-block;
  position: relative;
  color: #ff7708;
  padding: 0;
  border: none;
  background: none;
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.universal-borderless-button::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff7708;
  transform-origin: bottom right;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  opacity: 0;
}

.universal-borderless-button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  opacity: 1;
}

.universal-borderless-button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  opacity: 1;
}

.button-terciary {
  background-color: var(--actual-terciary);
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  border: none;
  transition: 0.3s all;
  font-weight: 700 !important;
  padding: 15px;
  color: black;
}

.button-terciary:hover {
  background-color: rgb(220, 220, 220);
}

.disabled {
  background-color: var(--disabled);
  cursor: default;
  border-color: var(--disabled);
}
.disabled:hover {
  background-color: var(--disabled);
}

.disabled-svg {
  filter: invert(39%) sepia(54%) saturate(10%) hue-rotate(335deg)
    brightness(96%) contrast(84%);
}

.small-btn {
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.super-small-btn {
  padding: 3px !important;
  font-size: 12px !important;
}

.square-btn {
  padding-inline: 6px;
  padding-block: 6px;
}

.big-btn {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.button-italic {
  font-style: italic;
  background: none;
  border: none;
  width: 50px;
  cursor: pointer;
}

.item-container {
  margin-top: 0.5in;
  margin-bottom: 0.75in;
  padding-left: 5vw;
  padding-right: 5vw;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.displaynone {
  display: none;
}

.input-container:focus-within .input-topper {
  display: inline-block;
}

.circle {
  border: 1px solid var(--gray-line);
  border-radius: 100px;
  padding: 5px 15px;
}

textarea {
  font-size: 17px;
  margin-top: -0.5px;
  margin-left: -0.5px;
}

td {
  padding: 5px;
  font-size: 0.95em;
}

.mr15 {
  margin-right: 15px;
}

.wide {
  width: 100%;
}

.breadcrumb-button {
  cursor: pointer;
  background: none;
  border: none;
  transition: all 0.25s;
  padding-left: 0px;
}

.breadcrumb-button:hover {
  color: var(--orange);
}

.breadcrumb-body {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 8px;
}

.bc-text {
  font-weight: 600;
  color: var(--light-gray);
  font-size: 1rem;
}

.breadcrumb-button:hover .bc-icon,
.breadcrumb-button:hover .bc-text {
  filter: invert(52%) sepia(71%) saturate(2389%) hue-rotate(353deg)
    brightness(101%) contrast(106%);
}

.breadcrumb-container-listing {
  margin-left: -5%;
  margin-top: -20px;
}

.breadcrumb-container-user-page {
  margin-left: -9.5vw;
  margin-top: 210px;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 80%;
}
.landing-title {
  color: var(--dark-blue);
}
h1 {
  margin-bottom: 10px;
  font-size: 42px;
  font-weight: 400;
}

.row-item-header {
  color: var(--dark-blue);
  margin-block: 10px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.landing-container {
  display: flex;
  flex-direction: column;
}
.landing-banner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.landing-header {
  padding-block: 115px;
  padding-left: 40px;
  padding-right: 40px;
  align-self: flex-start;
  background-color: white;
  z-index: 9;
}
.landing-image-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  position: absolute;
  right: 0;
  margin-right: 50px;
}
.landing-image {
  width: 700px;
  height: 500px;
  object-fit: cover;
}
.text-bigger {
  font-size: 24px;
}
.landing-testimonial {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.testimonial {
  background-color: rgba(255, 255, 255, 0.95);
  width: fit-content;
  align-self: center;
  padding: 5px;
}
.testimonial-quote {
  font-size: 24px;
  font-weight: 500;
  color: var(--dark-blue);
}
.landing-row {
  background-color: white;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid var(--gray-line);
  padding-block: 50px;
  gap: 50px;
}
.testimonial-row {
  padding-block: 50px;
}
.row-item-text {
  line-height: 24px;
}
.landing-row .landing-row-item {
  width: 30%;
}
@media screen and (max-width: 1300px) {
  .landing-image {
    width: 600px;
    height: 500px;
    object-fit: cover;
  }
  .landing-header {
    padding-block: 115px;
    padding-left: 60px;
  }
  h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 1000px) {
  .landing-row {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding-block: 65px;
  }
  .landing-row .landing-row-item {
    width: 70%;
  }
  .row-item-text {
    text-align: center;
    width: 100%;
  }
  .landing-row-item {
    align-self: center;
  }
  .row-item-header {
    text-align: center;
  }
  .landing-header {
    flex-direction: column;
  }
  .landing-image {
    width: 400px;
    height: 500px;
    object-fit: cover;
  }
}

@media screen and (max-width: 650px) {
  .landing-header {
    align-self: unset;
    padding-left: 0;
    text-align: center;
    padding-right: 0;
  }
  .landing-banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .landing-image-container {
    position: relative;
    margin-right: 0;
    display: unset;
    align-self: unset;
  }
  .landing-image {
    width: 100%;
    height: unset;
  }
}

@media screen and (max-width: 875px) {
  .landing {
    width: 80vw;
  }
}

@media screen and (max-width: 420px) {
  .button-primary {
    font-size: 0.8rem;
  }
  .button-terciary {
    font-size: 0.8rem;
  }
  .big-btn {
    padding: 10px;
    padding-inline: 14px;
  }
  .page-container {
    width: auto;
    margin-inline: auto;
  }
}

.user-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

/* used to align each to have same starting height */
.faux-header {
  position: sticky;
  height: 20px;
}

.margin-bottom-12px {
  padding-bottom: 12px;
}

.zindex700 {
  z-index: 700 !important;
}

.align-end {
  justify-content: flex-end !important;
}

.transition-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 145px;
  margin-left: 8px;
  font-size: 22px;
  color: var(--orange);
  font-weight: 400;
}
