.listings-parent {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 90vw;
}

.blank {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  padding: 20px;
}

.listings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 1rem;
  padding-bottom: 50px;
  padding-top: 60px;
  justify-content: flex-start;
}

.listing-container {
  width: 100%;
  max-width: 320px;
  height: 385px;
  background-color: white;
  position: relative;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-large);
  transition: transform 0.3s;
}

/* no filtering on mobile rn */
@media screen and (max-width: 420px) {
  .listings {
    padding-top: 20px;
  }
  .listing-container {
    max-width: unset;
  }
}

.thumbnail-container img {
  width: 100%;
  height: 99%;
  object-fit: cover;
}

.thumbnail-container {
  height: 350px;
}

.listings-image {
  border-radius: var(--border-radius-large);
}
.reserved-listing {
  color: var(--disabled);
}
.listing-container:hover {
  cursor: pointer;
  transform: scale(1.005);
}

.upper-corner {
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  right: 0;
  gap: 10px;
  margin-top: 10px;
  font-weight: 800;
  width: 90%;
  margin-right: 5%;
}

.upper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border-radius: var(--border-radius-large);
  width: fit-content;
  height: 30px;
  color: var(--orange);
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  gap: 6px;
  font-size: smaller;
  box-shadow: var(--box-shadow);
}

.listing-body {
  width: 100%;
  height: 115px;
  background-color: white;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 15px 15px;
  text-align: left;
  border-top: 1px solid rgba(180, 180, 180, 0.535);
  transition: all 0.3s ease-in-out;
}

.text-container {
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  position: absolute;
  top: 50%;
  width: 88%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-container2 {
  text-align: left;
  margin-right: 10%;
  margin-left: 5%;
}

.header {
  font-size: larger;
  font-weight: bold;
  padding-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4em;
}

.qty {
  float: right;
  margin-bottom: -10px;
}

.small {
  font-size: 0.8rem;
}

.loading-font {
  display: "flex";
  align-items: center;
  justify-content: center;
  margin-left: 45px;
}

.create-listing-btn {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 57px;
  height: 54px;
  justify-content: center;
  align-items: center;
  transition: width 0.1s ease-out;
  display: inline-flex;
  border-radius: var(--border-radius-large);
  border: none;
  cursor: pointer;
  white-space: nowrap;
  z-index: 70;
  background-color: var(--actual-terciary);
}

.create-listing-btn:hover {
  width: 122px;
}

.create-listing-btn .plus-icon {
  margin-right: 10px;
  transition: opacity 0.1s ease-out;
  color: var(--gray);
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  opacity: 1;
}

.create-listing-btn:hover .plus-icon {
  opacity: 0;
}

.create-listing-btn .text {
  font-size: 15px;
  opacity: 0;
  transition: opacity 0.05s ease-out;
  color: var(--gray);
  z-index: 1;
  margin-right: 5px;
}

.create-listing-btn:hover .text {
  opacity: 1;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  cursor: pointer;
  padding: 10px;
}

.pagination a {
  padding: 10px;
  margin: 0 10px;
  color: var(--orange);
  text-decoration: none;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.pagination a .pagination__label {
  margin-top: auto;
  position: relative;
  bottom: 2px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: var(--orange);
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  background-color: none;
  cursor: not-allowed;
}

.pagination__divider_prev {
  display: inline-block;
  width: 1px;
  height: 42px;
  background-color: #ccc;
  margin-top: -7px;
  margin-bottom: -5px;
  margin-left: 12px;
  margin-right: 5px;
  vertical-align: middle;
}

.pagination__divider_next {
  display: inline-block;
  width: 1px;
  height: 42px;
  background-color: #ccc;
  margin-top: -7px;
  margin-bottom: -5px;
  margin-left: 5px;
  margin-right: 12px;
  vertical-align: middle;
}

@media screen and (min-width: 320px) and (max-width: 950px) {
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    padding: 15px;
  }
}
.create-listing-btn-dashboard {
  position: relative;
  align-self: flex-end;
  margin-bottom: -69px;
  margin-top: 15px;
}

.maker-listing-image {
  width: 10vw;
  height: 10vw;
  max-width: 150px;
  max-height: 150px;
  min-width: 125px;
  min-height: 125px;
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.maker-listing-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.maker-listing-taker-image {
  width: 5vw;
  height: 5vw;
  max-width: 75px;
  max-height: 75px;
  min-width: 60px;
  min-height: 60px;
}

.maker-listing-taker-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listings-taker-image {
  border-radius: 50px;
  box-shadow: var(--box-shadow);
}

.map-view {
  width: 100%;
  margin-top: 80px;
  padding-top: 50px;
  display: flex;
  gap: 10px;
  padding-inline: 50px;
  box-sizing: border-box;
}
.map-view-left {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  overflow-y: scroll;
  padding-inline: 20px;
  height: calc(100vh - 150px);
  padding-top: 5px;
  margin-top: -4px;
  padding-bottom: 100px;
  box-sizing: border-box;
}
.big-map-view {
  width: 100%;
  height: calc(100vh - 150px);
  border-radius: 10px;
}
.map-view-right {
  flex-grow: 2;
  height: 100vh;
}
.map-marker {
}
