.big-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--actual-terciary);
  border-radius: var(--border-radius-large);
  margin-left: 5vw;
  margin-top: 75px;
  cursor: pointer;
  align-items: center;
  height: 45px;
  z-index: 10;
}
.bc-container {
  height: 100%;
  border: none;
  display: grid;
  cursor: pointer;
  grid-template-columns: 0fr;
  transition: all 0.12s ease-in-out;
  display: visible;
  cursor: pointer;
}
.bc-button {
  height: 97%;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--gray);
  font-weight: 600;
  text-align: left;
}
@media screen and (max-width: 420px) {
  .big-container {
    margin-left: 9vw;
  }
}

.bc-message {
  opacity: 0;
  transition: all 0.12s ease-in-out;
}

.bc-container > .bc-message {
  overflow: hidden;
}

.big-container:hover .bc-container {
  border-radius: var(--border-radius-medium);
  width: auto;
  grid-template-columns: 3fr;
  transition: all 0.12s ease-in-out;
}

.big-container:hover .bc-message {
  opacity: 1;
  padding-left: 20px;
  padding-right: 15px;
  transition: all 0.12s ease-in-out;
}
