.footer-container {
  position: relative;
  border-top: 1px solid var(--gray-line);
  background-color: var(--background);
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-text {
  text-align: center;
}
