@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.pop-up-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  margin-top: -120px;
}

.redirectLogin {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.redirectLogin:hover {
  width: fit-content;
  color: #ff7709;
  display: inline-block;
  margin: 0 auto;
  margin-top: -0.5px;
  text-align: center;
}

.redirectSignup {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.redirectSignup:hover {
  width: fit-content;
  color: #ff7709;
  display: inline-block;
  margin: 0 auto;
  margin-top: 0.5px;
  text-align: center;
}

.errorMessage {
  color: red;
  font-size: 14px;
}

.white-text {
  color: white !important;
}

.redirectLogin:hover ~ .errorMessage {
  margin-top: -1.5px;
}

.redirectSignup:hover ~ .errorMessage {
  margin-top: -1px;
}

.pop-up-container {
  width: 400px;
  height: 70%;
  background-color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 35px;
  border-radius: var(--border-radius-large);
}

.signup {
  display: block;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  height: 30px;
  padding-left: 10px;
  margin-top: 2px;
  font-size: 15px;
  text-align: left;
  width: 100%;
  padding: 20px;
}

.signup:focus {
  border: 1px solid #ff6708;
  outline: none;
  background: var(--input-background);
}

.signup-form {
  display: flex;
  flex-direction: column;
  right: 10%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
}

.signup-form::-webkit-scrollbar {
  display: none;
}

.input-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.in-input {
  position: fixed;
  padding-right: 10px;
}

.x-button {
  position: absolute;
  margin-top: -30px;
  margin-left: 375px;
}

.modal-body {
  width: 100%;
}

.customModal {
  border-radius: 8px;
  gap: 2rem;
  padding-block: 1.5rem;
  width: 400px;
}

.profile-upload-modal {
  width: 250px;
  border-radius: 8px;
  gap: 2rem;
  padding-block: 1.5rem;
  overflow-y: unset !important;
}

.captcha-container {
  width: fit-content;
  margin-inline: auto;
  margin-block: 0.5rem;
}

.login-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
}

/* for mobile devices */
@media screen and (max-width: 475px) {
  .customModal {
    width: auto;
    padding-top: 2rem;
  }
  .captcha-container {
    margin-left: -1rem;
  }
  .captcha-login {
    margin-left: -0.5rem;
  }
  .login-header {
    text-align: center;
  }
  .login-buttons {
    gap: 10px;
  }
}

@media screen and (max-width: 420px) {
  .captcha-login {
    margin-left: -1rem;
  }
}
