.home-button {
  font-size: 32px;
  color: var(--orange);
  font-weight: 550;
  background: none;
  border: none;
  padding: 25px;
}

.home-button-maker {
  color: white;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: var(--background);
  border-bottom: 1px solid var(--gray-line);
  align-items: center;
  z-index: 100;
  height: 80px;
}

.navbar-maker {
  background: white;
}

.with-transition {
  background: #ff7708;
  background-size: 200% 200%;
  animation: gradientAnimation 15s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.navbar-items {
  gap: 10px;
  padding: 25px 0;
  padding-right: 40px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .home-button {
    color: var(--orange);
    font-weight: 550;
    background: none;
    border: none;
  }
  .home-button-maker {
    color: white;
    padding-left: 20px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .home-button {
    color: var(--orange);
    font-size: 28px;
    font-weight: 550;
    background: none;
    border: none;
  }
  .home-button-maker {
    color: white;
  }
}

.txt-over-button {
  color: #ffffff;
  background-color: var(--orange);
  border: 1px solid var(--orange);
  font-weight: 800;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.tob-taker {
  background-color: white;
  border: 1px solid white;
  color: var(--gray);
  text-align: right;
  margin-top: -5px;
}
