.search-container {
  position: absolute;
  left: calc(50% - 140px);
  display: flex;
}

/* no search on mobile rn */
@media screen and (max-width: 420px) {
  .search-container {
    display: none;
  }
}

.searchbar {
  font-size: 24px;
  border-radius: 8px 0px 0px 8px;
  border-top: 2px solid var(--orange);
  border-bottom: 2px solid var(--orange);
  border-left: 2px solid var(--orange);
  border-right: none;
  padding: 5px;
  width: 250px;
}

.searchbar:focus {
  border-color: var(--orange);
  outline: none;
}

.search {
  text-decoration: none;
  font-weight: 100;
  background-color: var(--orange);
  font-size: 24px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0px 8px 8px 0px;
  margin-left: -2px;
  border: none;
  cursor: pointer;
  width: 50px;
}

.search-buttons {
  display: flex;
  position: relative;
  align-items: center;
}

.search:hover,
.search:hover .search-icon circle:nth-of-type(2) {
  background-color: #ff9b4a;
  fill: #ff9b4a;
}

.search-variant {
  padding: 0px 20px;
  border-radius: 0px;
  border-top: 2px solid var(--orange);
  border-bottom: 2px solid var(--orange);
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .search-container {
    position: absolute;
    left: calc(50% - 75px);
  }

  .searchbar {
    width: 100px;
    margin-left: -25px;
  }
}

@media screen and (min-width: 575px) and (max-width: 768px) {
  .search-container {
    position: absolute;
    left: calc(50% - 100px);
  }

  .searchbar {
    width: 150px;
  }
}
