.two-buttons {
  display: flex;
  position: relative;
  margin: auto;
}

.right-side {
  border-radius: var(--border-radius-medium);
}

.left-side {
  border-radius: var(--border-radius-medium);
}

.reserve-confirm-btn {
  padding: 10px;
  margin-top: 20px;
  font-size: 0.8rem;
}

.create-listing-topper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  margin-top: 80px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .create-listing-topper {
    margin-top: 100px;
  }
}

.qty-btn {
  border: none;
  border-radius: var(--border-radius-medium);
  background-color: var(--actual-terciary);
  cursor: pointer;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 700 !important;
  transition: all 0.3s ease-in-out;
}
.qty-btn:hover {
  filter: brightness(90%);
}
.qty-btn-active {
  filter: brightness(85%);
}
.qty-btn-active:hover {
  filter: brightness(80%);
}
.qty-btn-many-active {
  border-radius: var(--border-radius-medium) 0px 0px var(--border-radius-medium);
  padding: 9.5px 19.5px 9.5px 19.5px;
  border: none;
  z-index: 120;
}

.input-hidder {
  display: grid;
  grid-template-columns: 0fr;
  transition: grid-template-columns 0.5s;
  position: absolute;
  z-index: -100;
  margin-left: -5.5px;
  padding: 5.5px;
  opacity: 0;
}

.input-hidder > div {
  overflow: hidden;
}

.input-hidder-active {
  grid-template-columns: 3fr;
  width: auto;
  cursor: text;
  opacity: 1;
  z-index: 100;
}

.list-it-again {
  margin-left: 40px;
}

.listing-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
}

.small-listing-image {
  position: relative;
  height: 4rem;
  width: 4rem;
  border-radius: 5px;
}

.autofill-button {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  width: 20rem;
  gap: 0.5rem;
}
.autofill-button-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.loading-div {
  margin-top: 2rem;
  width: 41rem;
  height: 25rem;
}

.loading-div-short {
  margin-top: 1rem;
  width: 41rem;
  height: 15rem;
}
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-between;
  width: 80%;
  margin-inline: auto;
  height: 100%;
}
.customModalWide {
  width: fit-content;
}
.no-margin {
  margin: 0;
}
.no-bold {
  font-weight: 400;
}

.center {
  text-align: center;
}

.title-label {
  font-size: 20px;
  color: #5b5a5a;
  margin-left: 25px;
}

.file-label {
  box-sizing: border-box;
  border-radius: var(--border-radius-medium);
  font-size: 20px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  color: #5b5a5a;
}

.error-message {
  color: #cc0000;
  font-size: 14px;
}

.reg-input {
  display: block;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-radius: var(--border-radius-medium);
  height: 30px;
  padding-left: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: left;
  padding: 20px;
  width: 100%;
}

.reg-input:focus {
  border: 1px solid #ff6708;
  outline: none;
  background: var(--actual-terciary);
}

.number-input {
  text-align: right;
  height: 20px;
  z-index: 999;
}

.date-input {
  padding: 9px;
  height: auto;
  margin: 0px;
  margin-top: 15px;
  width: 35%;
}

.reg-label {
  font-size: 20px;
  color: #5b5a5a;
  margin-top: 15px;
  margin-bottom: 5px;
}

.reg-label-2 {
  margin-top: 20px;
  font-size: 16px;
  color: #5b5a5a;
}

.reg-select {
  display: flex;
  padding: 5px;
  margin-top: 2px;
  font-size: 15px;
  border: 1px solid #c4c4c4;
  border-radius: var(--border-radius-medium);
  margin-top: 15px;
}

.dropdown-select {
  background-color: white;
  position: relative;
  padding: 12px !important;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.dropdown-select:focus {
  border: 1px solid var(--orange);
  outline: none;
  background: var(--actual-terciary);
}

.reg-text-area {
  display: block;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-radius: var(--border-radius-medium);
  padding: 20px;
  padding-top: 10px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  height: 140px;
  max-width: 100%;
  min-width: 100%;
  min-height: 60px;
  margin-top: 15px;
  resize: none;
}

.reg-text-area:focus {
  outline: none;
  background: var(--actual-terciary);
  border: 1px solid #ff6708;
}

.small-text-area {
  height: 80px;
  margin-bottom: 0;
  margin-top: 15px;
}

.reg-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: var(--border-radius-large);
  overflow: hidden;
  margin-top: 15px;
  border: 1px solid #e0e0e0;
}

.small-td {
  font-weight: 300;
  font-size: 14px;
  padding: 15px;
  word-wrap: break-word;
  word-break: break-all;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

.small-td:last-child {
  border-bottom: none; /* Remove bottom border from last row */
}

.header-td {
  text-align: right;
  font-weight: 600;
  background-color: transparent;
  border-bottom: 1px solid white;
}
.tr {
  border-bottom: 1px solid #e0e0e0;
}

.button-separator {
  margin: 0 10px;
  border-left: 1.5px solid #ff7708;
  height: 17px;
  vertical-align: middle;
  position: relative;
  top: 3px;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-top: 5px;
  width: 100%;
  align-self: flex-end;
}

.create-listing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35rem;
  margin-top: 3rem;
  margin-inline: auto;
}

@media screen and (max-width: 420px) {
  .create-listing-container {
    width: 90%;
  }
  .customModalWide {
    width: 85%;
    margin-inline: auto;
  }
  .listing-columns {
    grid-template-columns: 1fr;
    margin-inline: auto;
    margin-top: 1rem;
  }
  .autofill-button {
    margin-inline: auto;
  }
  .title-label {
    margin-left: 20px;
  }
}

.num-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 3fr;
}

.green {
  color: #649d45;
  margin-right: 15px;
}
