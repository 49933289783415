.settings-line {
  color: #f4f4f4;
  margin-top: -40px;
  margin-bottom: 60px;
  height: 1px;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 40px;
  grid-column-gap: 30px;
  padding-bottom: 50px;
  margin-top: 20px;
}

.width-100 {
  width: 100%;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.same-item {
  padding-top: 30px;
  padding-bottom: 15px;
  border-radius: var(--border-radius-large);
  border: none;
  background: var(--db-background-secondary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-container {
  text-align: left;
  margin-right: 10%;
  margin-left: 5%;
}

.cp {
  margin-bottom: 0px;
  padding-bottom: 6px;
}

.custom-p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #8b8c8d;
  margin-top: 0px;
  padding-top: 0px;
}

.secondary-subheader {
  font-size: 25px;
}

.subheader {
  font-size: 27px;
}

@media screen and (max-width: 875px) {
  .container {
    padding-top: 20px;
    width: 90%;
  }
}

.same-item-disabled {
  background-color: #e0dfdf;
  cursor: auto;
}

.mc {
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}
