.rating-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 999;
  border: none;
  outline: none;
}

.rating-popup-content {
  background-color: #fff;
  padding-top: 1.5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 3vw;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8rem;
}

.picture-popup-content {
  background-color: #fff;
  padding-top: 1.5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 3vw;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: auto;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: 8rem;
}

@media screen and (max-width: 420px) {
  .picture-popup-content {
    height: fit-content;
  }
}

.default-popup-content {
  background-color: #fff;
  padding-top: 1.5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 3vw;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: 8rem;
}

.login-popup-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 380px;
  height: 500px;
  padding: 50px 40px;
  border-radius: var(--border-radius-large);
  position: relative;
  margin-top: 8rem;
}

.signup-popup-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  padding: 50px 40px;
  border-radius: var(--border-radius-large);
  margin-top: 8rem;
}

.rating-popup-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

@media screen and (max-width: 420px) {
  .rating-popup-rating {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.rating-popup-textarea {
  width: 100%;
  height: 120px;
  margin-top: 20px;
  margin-bottom: 40px;
  resize: none;
  padding: 8px;
  border-radius: var(--border-radius-large);
  border: 2px solid #ccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.rating-popup-textarea:focus {
  border: 2px solid var(--orange);
  outline: none;
}

.tooltip {
  background-color: var(--light-gray);
}

.blank-text {
  color: white !important;
}

.popup-containers {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

@media (max-width: 1000px) {
  .popup-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    height: 80%;
  }
}

.full-width {
  width: 100%;
}
