.reserve-container {
  background-color: white;
  height: 300px;
  border-radius: var(--border-radius-medium);
  padding: 15px;
  text-align: left;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.reserve-interior {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  padding-left: 15px;
  height: 100%;
}

.wide {
  width: 100%;
}

.reserve-btn {
  padding: 15px;
  font-size: 1rem;
}

.reserve-cont-btn {
  padding: 15px;
  margin-top: 20px;
  font-size: 1.05vw;
}

.reserve-confirm-btn {
  padding: 15px;
  margin-top: 20px;
  font-size: 1.05vw;
}

.rsv-header {
  font-size: 1.3vw;
  font-weight: 700;
  color: var(--gray);
  margin-bottom: 2px;
  font-size: 15px;
  text-align: left;
}
.rsv-subheader {
  font-size: 1vw;
  font-weight: 700;
  color: var(--gray);
  margin-bottom: 5px;
  font-size: 15px;
}

.left {
  text-align: left;
}
.qty-on-hover {
  margin-left: 20px;
  color: var(--orange);
  font-weight: 800;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: right;
  width: max-content;
}

.visible {
  opacity: 1;
}

.reservation-date {
  color: var(--orange);
  font-weight: 1000;
  padding-bottom: 20px;
}

.cancel {
  border: none;
  outline: none;
  background: none;
  font-size: 15px;
  font-style: italic;
  cursor: pointer;
  margin-left: -8px;
}
.cancel:hover {
  text-decoration: underline;
}
.res-confirm {
  width: 90%;
}
.above-button {
  margin-left: 20%;
  margin-bottom: -10px;
  font-size: 16px;
  padding-top: 5px;
  padding-left: 15px;
}

.orange {
  color: var(--orange);
}

.rsv-qty {
  display: inline-block;
  font-size: 1.5vw;
  padding-right: 15px;
}
.inline {
  display: inline-block;
}

.reserve-line {
  color: var(--gray-line);
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.space {
  margin-right: 8px;
}
.italic {
  font-style: italic;
  font-weight: 200;
}

.rsv-helpfultext {
  color: var(--light-gray);
  font-size: 16px;
  margin-bottom: 5px;
}

.alignright {
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.qty-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 10px;
}

.rsv-qty-btn {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  background: none;
  border: none;
  margin: 0;
  border-radius: var(--border-radius-large);
  background-color: rgb(249, 249, 249);
  cursor: pointer;
  width: fit-content;
}

.rsv-qty-btn:hover {
  background-color: rgb(232, 232, 232);
  transition: all 0.3s;
}

.qty-r {
  display: flex;
  flex: row;
  align-items: center;
}
.qty-left {
  text-align: left;
  margin-right: 0px;
  padding: 15px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: 600;
  padding-right: 25px;
}

.qty-right {
  text-align: left;
  padding-left: 0px;
  margin-left: 0px;
  padding: 15px;
  padding-top: 20px;
  color: var(--orange);
  font-weight: 800;
}

.qty-line {
  height: 30px;
  margin-right: 5px;
  width: 2px;
  background-color: #d0d0d0;
  display: inline-block;
  align-self: center;
}

.rsv-qty-btn-clicked {
  background-color: var(--light-gray);
  color: white;
}

.rsv-qty-btn-clicked:hover {
  background-color: var(--gray);
  color: white !important;
}

.back {
  border: none;
  outline: none;
  background: none;
  font-size: 15px;
  padding: 0 !important;
  cursor: pointer;
  color: #5b5a5a;
}
.back:hover {
  text-decoration: underline;
}

.cost-container {
  width: 80%;
  text-align: left;
  margin-bottom: 10px;
}

.cost-item {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 25px;
}
.cost-line {
  position: absolute;
  width: 200px;
  height: 1px;
  right: 0;
  margin-right: 275px;
  background-color: #d0d0d0;
}
.darker {
  color: var(--gray);
  font-weight: 600;
}

.white {
  color: white;
}

.rsv-qty-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.rsv-cnfrm-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 20px;
}

.small-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.small-notes {
  margin-left: 10px;
  font-size: 0.9rem;
}

.two-buttons-reserve {
  display: flex;
  width: 100%;
}

.left-side {
  border-radius: 10px 0px 0px 10px;
}

.right-side {
  border-radius: 0px 10px 10px 0px;
}

.centered {
  text-align: center;
  margin: auto;
}

.listing-bold {
  font-weight: 700;
  color: var(--gray);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .rsv-subheader {
    font-size: 15px;
    font-weight: 500;
    color: var(--gray);
    margin-bottom: 10px;
  }

  .rsv-header {
    font-size: 15px;
    font-weight: 500;
    color: var(--gray);
    margin-bottom: 2px;
  }

  .rsv-helpfultext {
    color: var(--light-gray);
    font-size: 15px;
  }
}

.up-time {
  width: 100% !important;
}
