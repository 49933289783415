.openListing-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  width: 90%;
  margin-left: 5%;
  padding-top: 20px;
}

@media screen and (max-width: 420px) {
  .openListing-container {
    width: 95%;
    margin-left: 2.5%;
  }
}

.twocolumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
}

.col1 {
  grid-area: 1 / 1 / 2 / 2;
}

.col2 {
  grid-area: 1 / 2 / 2 / 3;
}
.image {
  grid-area: 1 / 1 / 2 / 2;
}
.reserve {
  grid-area: 1 / 2 / 2 / 3;
}
.details {
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.map {
  grid-area: 2 / 2 / 3 / 3;
  justify-self: end;
}

/* Single-column layout */
@media (max-width: 1000px) {
  .twocolumns {
    grid-template-columns: 1fr; /* Set to a single column */
  }

  .col1,
  .col2 {
    grid-area: unset; /* Remove grid area */
  }

  .image,
  .reserve,
  .details,
  .map {
    grid-area: unset; /* Remove grid area */
  }
}

.floatleft {
  text-align: left;
}

.image-container {
  width: 100%;
  background-color: white;
  border-radius: var(--border-radius-large);
  height: 460px;
  box-shadow: var(--box-shadow);
}

.image-container img {
  width: 100%; /* Make the image fill the container horizontally */
  height: 100%; /* Make the image fill the container vertically */
  object-fit: cover;
}

.item-header {
  padding-top: 20px;
  padding-bottom: 5px;
}
.item-header-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-name {
  font-size: 26px;
  font-weight: 800;
}

.map {
  height: 440px;
  background-color: var(--orange);
  border-radius: var(--border-radius-large);
  margin-top: 20px;
  position: relative;
  z-index: -1000;
}

.info-container {
  background-color: white;
  min-height: 100px;
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  padding-top: 20px;
  position: relative;
  box-shadow: var(--box-shadow);
  gap: 15px;
}

.location-container {
  background-color: white;
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  padding-top: 20px;
  box-shadow: var(--box-shadow);
  position: relative;
  z-index: 10;
}

.item-details {
  align-self: flex-end;
}

.padding {
  padding-left: 15px;
}

.arrow {
  background-color: whitesmoke;
  width: 65px;
  height: 55px;
  border-radius: 100%;
  opacity: 0.7;
  cursor: pointer;
  position: relative;
}

.arrow-left {
  border-radius: 15px 7px 7px 15px;
}
.arrow-right {
  border-radius: 7px 15px 15px 7px;
}

.image-container:hover .arrow-hover {
  scale: 1.07;
  transition: all 0.3s ease-in-out;
}

.arrow-hover {
  transition: all 0.3s ease-in-out;
}

.arrow-hover:hover {
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.arrow-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-button:hover {
  opacity: 0.9;
}

.arrow-disabled {
  transition: all 0.3s ease-in-out;
}

.dot {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: var(--background);
  opacity: 0.6;
}

.active-dot {
  opacity: 0.9;
}

.listing-top-bar {
  display: flex;
  width: 100%;
  margin-top: 80px;
  box-sizing: border-box;
  background-color: var(--orange-or-black);
  margin-bottom: 15px;
  position: sticky;
  top: 80px;
  z-index: 80;
}
.lv-faux-header {
  margin-top: 80px;
}
.ltb-wrapper {
}
.ltb-container {
  position: relative;
  display: flex;
  width: 100%;
  padding-inline: 25px;
  justify-content: space-between;
  box-sizing: border-box;
}
.map-div {
  width: 100%;
  height: 400px;
  border-radius: 0px 0px var(--border-radius-medium) var(--border-radius-medium);
}
.ltb-buttons {
  display: flex;
}
.ltb-button {
  padding: 15px;
  padding-block: 10px;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--orange-or-white);
  border: none;
  cursor: pointer;
  color: var(--white-or-black);
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}
.ltb-button:hover {
  background-color: var(--o-w-hover);
}
.ltb-active {
  background-color: var(--o-w-hover);
}
.ltb-main {
  font-size: 22px;
}
.lv-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: 10%;
  box-sizing: border-box;
}
.lv-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.lv-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
}
.lv-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
}

.date-enterer {
  width: 100% !important;
}

.dei {
  background-color: #c4c4c41e;
  margin-top: 0px !important;
}

.lc-taker {
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0px 0px;
}

.show-date {
  padding: 9px;
  margin: 0px;
  display: block;
  box-sizing: border-box;
  background-color: #c4c4c41e;
  border: 1px solid #c4c4c41e;
  border-radius: var(--border-radius-medium);
  font-size: 15px;
  text-align: left;
  padding: 7.5px;
  width: 100%;
  padding-left: 12px;
}

.strikethrough {
  text-decoration: line-through;
}
