.image-uploader {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
}

.image-uploader-circle {
  width: 14vw;
  min-width: 140px;
  max-width: 190px;
  border-radius: 20px;
  margin: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  padding-bottom: 25px;
}

.image-circle {
  width: 10vw;
  height: 10vw;
  min-width: 100px;
  min-height: 100px;
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  position: relative;
  padding-top: 10px;
  padding-bottom: 30px;
}

.default-circle {
  margin: auto;
  width: 8vw;
  height: 8vw;
  min-width: 75px;
  min-height: 75px;
  max-width: 100px;
  max-height: 100px;
}

.default-circle:hover {
  filter: brightness(80%);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.default-circle-selected {
  filter: brightness(80%);
  transition: all 0.3s ease-in-out;
}

.image-circle-input {
  visibility: hidden;
  z-index: -1999;
  position: absolute;
}

.image-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.default-images-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0.5rem;
  grid-column-gap: 3rem;
  border-radius: var(--border-radius-large);
  background-color: #f0f0f0;
  justify-items: center;
  align-items: center;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.image-circle:hover img {
  filter: #5b5a5a;
}

.image-circle-label {
  cursor: pointer;
  width: fit-content;
}

.remove-button {
  position: absolute;
  cursor: pointer;
  padding: 25px 30px 25px 30px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.image-box:hover .remove-button {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.image-box {
  width: 100%;
  height: 150px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-large);
}

.input-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Adjust the gap size between input elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-label {
  font-size: 16px;
  color: #5b5a5a;
  cursor: pointer;
  text-align: center !important;
  margin-inline: auto;
  padding-bottom: 25px;
}

.text-center {
  text-align: center !important;
}
