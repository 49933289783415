.reviews {
  margin-left: 0%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 200px;
}
.review-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  text-align: left !important;

  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-medium);
  padding: 40px;
  padding-top: 20px;
  position: relative;
  background-color: white;
}

.top {
  margin-top: 10px;
}

.rating-image-container {
  width: 3vw;
  height: 3vw;
  background-color: white;
}

.rating-image-container img {
  width: 100%;
  height: 99%;
  object-fit: cover;
}

.rating-image {
  border-radius: 50px;
  background-color: white;
}

.date-created {
  font-size: 16px;
  font-weight: 400;
}
