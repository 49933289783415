.filter-bar {
  position: fixed;
  z-index: 15;
  background: white;
  width: 100vw;
  border-top: 1px solid var(--gray-line);
  border-bottom: 1px solid var(--gray-line);
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

/* no filtering on mobile rn */
@media screen and (max-width: 420px) {
  .filter-bar {
    display: none;
  }
}

.filter-dropdown-container {
  position: relative;
  display: grid;
  width: 12rem;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.dropdown-wide {
  width: 14rem;
}
.filter-dropdown-container .filter-button {
  overflow: hidden;
  height: 0px;
  padding: 0px;
  opacity: 0;
  border-top: 0px;
  transition: height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}
.filter-dropdown-container:hover .filter-button {
  height: 3rem;
  opacity: 1;
  transition: height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}
.filter-dropdown-container .filter-dropdown-line {
  height: 0px;
  opacity: 0;
}
.filter-dropdown-container:hover .filter-dropdown-line {
  height: 1px;
  opacity: 1;
}

.filter-dropdown-container:hover {
  border-bottom: 1px solid var(--gray-line);
  border-left: 1px solid var(--gray-line);
  border-right: 1px solid var(--gray-line);
}

.top-filter {
  border: none;
  padding: 0.45rem 1rem;
  height: 2.5rem;
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
}
.filter-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--light-gray);
  padding: 0.8rem 1rem;
  box-sizing: border-box;
}
.filter-button:hover > span {
  color: var(--orange);
}

.filter-dropdown-line {
  position: relative;
  background-color: var(--gray-line);
  width: 60%;
  margin: auto;
  z-index: 9999;
}

.orange {
  background-color: var(--orange);
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .listing-bar-container {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .listing-bar-container {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}
