.message-open {
  position: fixed;
  margin-top: 80px;
  height: calc(95vh - 80px);
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  z-index: 85;
  overflow: none;
}

.chat-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.chat-image {
  width: 60px;
  height: 60px;
}

.message-sender {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.message-sender-input {
  width: 100%;
  height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: var(--border-radius-medium);
  padding: 20px;
  padding-top: 20px;
  padding-top: 10px;
  font-size: 16px;
  text-align: left;
  resize: none;
  font-family: unset;
}
.message-sender-input:focus {
  outline: none;
  background: var(--actual-terciary);
  filter: brightness(110%);
  border: 1px solid #ff6708;
}

.messages-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  margin-bottom: 20px;
  scrollbar-gutter: stable;
  scrollbar-color: var(--light-gray) var(--background);
  padding-right: 10px;
  padding-bottom: 20px;
}

.message {
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  border-radius: 5px;
}

.message-dark {
  background-color: var(--mild-gray);
}

.self-message {
  background-color: var(--orange);
  color: white;
  margin-left: auto;
}
.chats {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15x;
  width: fit-content;
  margin-inline: auto;
  margin-bottom: 80px;
  min-width: 500px;
}
.chat-container {
  width: 100%;
  flex-direction: row !important;
  align-items: center;
  padding-right: 0;
  color: black;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  gap: 20px !important;
  justify-content: unset !important;
}
.chat-container:hover {
  transform: scale(1.015);
}
.chat-displayer {
  margin-top: 90px;
}
.message-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 70%;
}
.message-name {
  text-align: left;
  font-size: 16px;
}
.message-time {
  font-size: 12px;
  color: var(--light-gray);
}
.time-self {
  text-align: right;
}
.message-content {
  font-style: italic;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 32px;
  font-size: 12px;
}
.orange-dot {
  width: 10px;
  height: 10px;
  background-color: var(--orange);
  border-radius: 50%;
  margin-right: -10px;
}
.white {
  background: none;
}
.chat-subheader {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: left;
  padding-left: 0;
  cursor: pointer;
  color: black;
}
.chat-subheader:hover {
  text-decoration: underline;
}

.chat-header {
  background: none;
  border: none;
  outline: none;
  text-align: left;
  padding-left: 0;
  cursor: pointer;
  color: black;
}

.chat-header:hover {
  text-decoration: underline;
}
.chat-header-column {
  display: flex;
  flex-direction: column;
}
.chats-container {
  margin-top: 90px;
  min-width: 500px;
}
@media screen and (max-width: 600px) {
  .message-open {
    box-sizing: content-box !important;
    padding-left: 50px !important;
    width: 90%;
  }
  .chat-container {
    width: 85vw;
  }
}

@media screen and (max-width: 420px) {
  .chat-header {
    font-size: 16px;
  }
  .hat-subheader {
    font-size: 14px;
  }
  .chat-image {
    width: 40px;
    height: 40px;
  }
}

.message-group {
  margin-bottom: 30px;
}
