.calendar {
  margin-top: 80px;
  display: flex;
  gap: 10px;
  padding-left: 10px;
  height: calc(80px + 90vh);
}
.calendar-container {
  position: relative;
  margin-inline: auto;
}

.calendar-header {
  position: sticky;
  top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
  background-color: var(--background);
  width: calc(100% + 14px);
  margin-left: -7px;
  padding-left: 4px;
}

.date-changer {
  display: flex;
  gap: 0.3rem;
  margin-left: auto;
}

.date-changer-button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.date-changer-button:hover {
  filter: brightness(120%);
}

.date-text {
  font-size: 30px;
  font-weight: 900;
  font-style: bold;
  color: var(--gray);
  -webkit-text-stroke: 1px var(--gray);
  width: fit-content;
}

.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  margin-top: 5px;
}

.calendar-day {
  width: 8.5rem;
  height: 7.5rem;
  padding: 0;
  text-align: center;
  box-shadow: var(--box-shadow);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: var(--gray);
  background-color: white;
  border-radius: var(--border-radius-medium);
  transition: all 0.3s ease-in-out;
}

.day-today {
  background-color: #484848;
  padding: 8px;
  border-radius: var(--border-radius-small) 0px var(--border-radius-small) 0px;
  color: white;
}

.calendar-day:hover {
  transform: scale(1.03);
}
.day-number {
  font-size: 20px;
  align-self: flex-start;
  font-weight: 900;
  padding-block: 5px;
  padding-left: 8px;
  margin-bottom: 8px;
}

.day-header-open {
  font-size: 22px;
  font-weight: 900 !important;
}
.other-month {
  background-color: var(--background);
  color: #ccc;
  cursor: unset;
}

.other-month:hover {
  transform: none;
}
.day-items {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.listing-item {
  width: calc(100% - 10px);
  background-color: var(--orange);
  color: white;
  padding-block: 2px;
  font-weight: 600;
  text-align: left;
  padding-left: 8px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  box-shadow: var(--box-shadow);
}

.reservation-item {
  background-color: var(--gray);
}

.item-start {
  border-radius: var(--border-radius-medium) 0px 0px var(--border-radius-medium);
  margin-left: 10px;
}
.item-end {
  border-radius: 0px var(--border-radius-medium) var(--border-radius-medium) 0px;
  margin-right: 10px;
  filter: brightness(92%);
}
.date-modal {
  border-radius: 8px;
  gap: 2rem;
  padding-block: 1.5rem;
}

.cal-filter-row {
  display: flex;
  gap: 5px;
  align-items: center;
}
.cal-filter {
  border: 2px solid;
  border-radius: var(--border-radius-small);
  padding: 3px;
  padding-inline: 7px;
  background: white;
  color: var(--gray);
  font-weight: 600;
  width: 120px;
}

.orange-filter {
  color: var(--orange);
}

.cal-filter-active {
  background-color: var(--gray);
  color: white;
  border-color: var(--gray);
}

.orange-filter.cal-filter-active {
  background-color: var(--orange);
  border-color: var(--orange);
}

.calendar-day-open {
  position: relative;
  right: 0;
  top: 0;
  width: 30vw;
  padding-top: 20px;
  height: calc(80px + 95vh);
  background-color: var(--background);
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  overflow-y: auto;
  padding-inline: 20px;
}

.open-day-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.close-btn {
  background: none;
  border: none;
  outline: none;
  color: black;
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
  align-self: flex-start;
}

.close-btn:hover {
  filter: brightness(150%);
}

.open-day-subheader {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
@media (width <= 1450px) {
  .calendar-day {
    width: 8rem;
    height: 7rem;
  }
  .calendar-day-open {
    width: 26vw;
  }
}

@media (width <= 1350px) {
  .calendar-day {
    width: 7.5rem;
    height: 6.5rem;
  }
  .calendar-day-open {
    width: 26vw;
  }
}

@media (width <= 1200px) {
  .calendar-day-open {
    position: fixed;
    margin-top: 80px;
    width: 40%;
  }
}
@media (width <= 1150px) {
  .calendar-day {
    width: 7rem;
    height: 6rem;
  }
}
@media (width <= 1000px) {
  .calendar-day-open {
    width: 50%;
  }
}
@media (width <= 900px) {
  .calendar-container {
    width: 100%;
  }
  .calendar-body {
    grid-template-columns: 1fr 1fr;
  }
  .calendar-day {
    width: 100%;
    min-height: 40px;
    height: max-content;
  }
  .listing-item {
    border-radius: 5px;
  }
  .calendar-day-open {
    width: 60%;
  }
}
.mobile-calendar-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
}

@media (width <= 500px) {
  .calendar-container {
    width: 100%;
  }
  .calendar-body {
    grid-template-columns: 1fr;
  }
  .calendar-day-open {
    box-sizing: border-box;
    width: 100%;
  }
  .calendar-header {
    padding-inline: 10px;
    background: white;
    width: 97%;
    margin-left: -10px;
  }

  .mobile-calendar-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
