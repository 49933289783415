.reservations-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  min-width: 50vw;
  margin-left: 12vw;
}

.listings-container {
  width: 70%;
}

.less-margin {
  margin-left: 2vw;
}

.reservation-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reservations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding-bottom: 40px;
}

.reservations-header {
  position: fixed;
  background-color: var(--background);
  z-index: 80;
  width: 100%;
  height: auto;
  padding: 30px 10px 15px 20px;
  margin-left: -20px;
  text-align: left;
}

.reservation-section {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.focused {
  position: sticky;
  top: 60px; /* Adjust the value to leave space for the fixed header */
}

.rsv-category {
  position: sticky;
  top: 125px; /* Height of navbar (80px) */
  background-color: var(--background);
  z-index: 70;
  width: 100%;
  padding: 0px 10px 10px 10px;
  text-align: left;
  color: var(--gray);
  font-size: large;
  margin-left: -20px;
  padding-left: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  padding-top: 10px;
}

.rsv-category-sub {
  position: sticky;
  top: 120px; /* Height of navbar (80px) + reservations header (50px) */
  background-color: var(--background);
  z-index: 70;
  width: 100%;
  padding-top: 0px;
  text-align: left;
  color: var(--light-gray);
  font-size: 16px;
  margin-left: -20px;
  padding-left: 20px;
  margin-bottom: 5px;
  font-weight: 400;
}

.reservation-container {
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-large);
  padding: 10px;
  padding-right: 15px;
  position: relative;
  background-color: white;
  z-index: 50;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  width: 70%;
  margin-top: 45px;
}
.reservation-container:hover {
  background-color: var(--background-secondary);
}

.under-listing {
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin-top: -20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 49;
  background-color: rgb(248, 248, 248);
  border-radius: 0px 0px var(--border-radius-large) var(--border-radius-large);
}

.reservation-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.reservation-details {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.reservation-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13.3px;
  align-self: center;
}

.reservation-buttons-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 13.3px;
  align-self: center;
}

.reservation-button {
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  font-weight: 600;
  padding: 15px;
  cursor: pointer;
  border: none;
  transition: 0.3s all;
  font-size: 13.3px;
}

.reservation-button:hover {
  background-color: rgb(220, 220, 220);
}

.reservation-button-orange {
  background-color: var(--orange);
  color: white;
}

.reservation-button-orange:hover {
  background-color: var(--orange-hover);
}

.button-under {
  position: relative;
  align-self: center;
}

.rsv-buttons {
  margin-bottom: 20px;
}

.rsv-filter {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--light-gray);
  transition: all 0.2s;
  font-size: large;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 10px;
}

.rsv-filter:hover {
  color: var(--orange);
}

.rsv-filter-clicked {
  color: var(--orange);
  font-weight: 800;
}

@media (max-width: 1000px) {
  .reservations-container {
    width: 100%;
  }
  .reservation-container {
    padding-block: 15px;
  }
  .reservation-row {
    align-items: flex-start;
  }
  .row1 {
    flex-direction: column;
    gap: 10px;
  }
  .reservation-buttons {
    align-self: flex-end;
    flex-direction: row;
  }

  .reservation-buttons-row {
    align-self: flex-end;
  }
}

.side-selector {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: -180px;
  margin-top: 110px;
  width: 150px;
}

.side-option {
  color: var(--light-gray);
  background: none;
  border: none;
  outline: none;
  text-align: right;
  font-size: 15px;
  transition: all 0.2s ease-in-out;
}

.side-option:hover {
  font-weight: 600;
  cursor: pointer;
  color: var(--orange);
}

.side-option-disabled:hover {
  font-weight: 400;
  color: var(--light-gray);
  cursor: default;
}

@media (max-width: 1200px) {
  .side-selector {
    display: none;
  }
  .reservations-container {
    margin-left: 0vw;
  }
}

/* no filtering on mobile rn */
@media screen and (max-width: 420px) {
  .reservations-container {
    width: 90%;
  }
}

.reservations .ml-container-wrapper {
  width: 49%;
}
@media screen and (max-width: 1000px) {
  .reservations .ml-container-wrapper {
    width: 100% !important;
  }
  .reservations {
    padding-inline: 20px;
  }
  .rsv-category {
    padding-inline: 20px;
    margin-left: unset;
  }
}
.ml-container {
  display: flex;
  gap: 15px;
  width: 100%;
  background-color: white;
  padding-right: 15px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  z-index: 15;
}
.ml-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-block: 15px;
  box-sizing: border-box;
  flex-grow: 2;
}

.ml-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--actual-terciary);
  filter: brightness(125%);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.ml-info:hover {
  filter: brightness(115%);
}
.ml-name {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.ml-dates {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  text-align: left;
}
.ml-date {
  text-align: left;
}
.ml-buttons {
  justify-content: flex-end;
  margin-right: 1px;
  display: flex;
  width: 100%;
  gap: 10px;
}

.ml-button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  background-color: var(--actual-terciary);
  filter: brightness(125%);
  font-weight: 600;
  padding-top: 5px;
  padding-inline: 5px;
}
.ml-button svg {
  height: 28px;
  width: 28px;
}

.ml-button:hover {
  filter: brightness(115%);
}

.ml-res {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 15px;
  padding-block: 10px;
  box-sizing: border-box;
  width: 100%;
  border-top: 3px solid var(--actual-terciary);
  background-color: white;
  filter: brightness(95%);
  box-shadow: var(--box-shadow);
  z-index: -10;
}

.ml-res-name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 15px;
  background: var(--actual-terciary);
  padding: 5px;
  padding-inline: 15px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  filter: brightness(125%);
}

.ml-res-name:hover {
  filter: brightness(115%);
}
.ml-res-buttons {
  display: flex;
  align-content: center;
  gap: 10px;
}

.ml-res-button {
  font-size: 14px;
  background: var(--actual-terciary);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 600;
  padding-top: 5px;
  padding-inline: 5px;
  filter: brightness(125%);
}
.ml-res-button svg {
  height: 28px;
  width: 28px;
}
.ml-res-button:hover {
  filter: brightness(115%);
}

.cur-win {
  width: 110px;
}

.rate-taker-button {
  height: fit-content;
  padding: 5px;
  margin-top: 5px;
}
