.maker-container {
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-medium);
  flex-direction: column;
  display: flex;
  padding: 15px;
  background-color: var(--background-secondary);
  border: none;
  justify-content: space-between;
  gap: 10px;
}

.user-details {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.maker-image {
  width: 80px;
  height: 80px;
  padding-bottom: 1px;
  border-radius: 50%;
}

.maker-name {
  font-weight: 800;
}

.message-maker {
  background-color: rgb(249, 249, 249);
  border-radius: var(--border-radius-large);
  font-weight: 600;
  padding: 15px;
  cursor: pointer;
  border: none;
  margin-left: 5px;
}

.message-maker:hover {
  background-color: rgb(232, 232, 232);
  transition: all 0.3s;
}

.five-mb {
  margin-bottom: 5px !important;
}

.vertical-line {
  position: relative;
  height: 70px;
  width: 1px;
  background-color: var(--light-gray);
  opacity: 0.3;
}

.continue-messaging {
  margin-left: auto;
  background-color: rgb(240, 240, 240);
  border: none;
  text-align: left;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  align-content: top;
  cursor: pointer;
  border-radius: 5px;
}
.continue-messaging:hover {
  background-color: rgb(220, 220, 220);
}
.bolder {
  color: black;
  font-weight: 700;
}
.content {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 20px;
}
